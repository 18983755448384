@tailwind base;

html {
  font-feature-settings: "kern";
}

@tailwind components;

.text-palt {
  font-feature-settings: "palt" 1;
}

@tailwind utilities;

.link-overlay {
  @apply relative;

  & .link-overlay-link::before {
    content: "";
    cursor: inherit;
    @apply block absolute inset-0 z-0;
  }
}

.entry-body {
  @apply text-base leading-normal break-words;

  & p {
    @apply mt-4;
  }

  & li + li {
    @apply mt-1;
  }

  & li > p + p {
    @apply mt-6;
  }

  & a {
    @apply text-blue-600 font-semibold hover:underline;
  }

  & a.anchor {
    float: left;
    line-height: 1;
    margin-left: -20px;
    padding-right: 4px;
    @apply text-gray-900;
  }

  & .anchor .icon-link {
    width: 18px;
    height: 18px;
    visibility: hidden;
  }

  & h1:hover .anchor .icon-link,
  & h2:hover .anchor .icon-link,
  & h3:hover .anchor .icon-link,
  & h4:hover .anchor .icon-link,
  & h5:hover .anchor .icon-link,
  & h6:hover .anchor .icon-link {
    visibility: visible;
  }

  & strong a {
    @apply font-bold;
  }
  & h1 {
    @apply leading-tight border-b text-4xl font-semibold mb-4 mt-6 pt-2 pb-2;
  }
  & h2 {
    @apply leading-tight border-b text-2xl font-semibold mb-4 mt-6 pt-2 pb-2;
  }
  & h3 {
    @apply leading-snug text-xl font-semibold mb-4 mt-5 pt-2;
  }
  & h4 {
    @apply leading-none text-base font-semibold mb-4 mt-5 pt-2;
  }
  & h5 {
    @apply leading-tight text-sm font-semibold mb-4 mt-5 pt-2;
  }
  & h6 {
    @apply leading-tight text-sm font-semibold text-gray-600 mb-4 mt-5 pt-2;
  }

  & blockquote {
    @apply text-base border-l-4 border-gray-300 pl-4 pr-4 text-gray-600;
  }

  & code {
    @apply font-mono text-sm inline bg-gray-200 rounded px-1 py-1;
  }

  & pre {
    @apply bg-gray-100 rounded p-4;
  }

  & pre code {
    @apply block bg-transparent p-0 overflow-x-scroll rounded-none;
  }

  & ul {
    @apply text-base pl-8 list-disc list-inside my-4;
  }
  & ol {
    @apply text-base pl-8 list-decimal list-inside my-4;
  }

  & table {
    @apply text-base border-gray-600 my-4;
  }

  & th {
    @apply border py-1 px-3;
  }

  & td {
    @apply border py-1 px-3;
  }
}
